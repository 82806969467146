<template>
  <div>
    <div>
      <h4>支付结果上传</h4>
      <el-upload
          class="upload-demo"
          drag
          :show-file-list="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :http-request="uploadFile">
        <i class="el-icon-upload"></i>
        <div v-if="upload">正在上传中...</div>
        <div v-else class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>

    </div>

    <br><br>

    <h4>导入结果，合计金额：{{ total }}</h4>
    <div>
      <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
        <el-table-column prop="record_id" label="任务ID"/>
        <el-table-column prop="account" label="账号"/>
        <el-table-column prop="serial_no" label="支付订单号"/>
        <el-table-column prop="amount" label="金额"/>
      </el-table>
    </div>
  </div>
</template>

<script>
import {apiImportTaskRecordAlipay} from '@/api/taskApi'
export default {
  data() {
    return {
      upload: false,
      tableData: [],
      total: 0,
    };
  },
  methods: {
    uploadFile(file) {

      if (this.upload) {
        return;
      }

      this.upload = true

      let data = new FormData()

      data.append('file', file.file)

      apiImportTaskRecordAlipay(data).then(res =>{
        this.$message.success('导入成功' + res.count + '条数据')
        this.tableData = res.data
        this.total = res.total
        this.upload = false
      }).catch(() =>{
        this.upload = false
      })
    },
  }
}
</script>
